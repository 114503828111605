var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": ['column', 'row'],
      "mx": "15px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": ['center', 'flex-start'],
      "min-w": ['250px']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "18px",
      "color": "primary.400",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-box', {
    attrs: {
      "mt": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "150%",
      "color": "#333333"
    }
  }, [_vm._v(" Jumlah porsi ")]), _c('c-box', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "h": "46px",
      "mt": "5px"
    }
  }, [_c('c-button', {
    attrs: {
      "flex-shrink": "0",
      "variant": "outline",
      "variant-color": "primary",
      "h": "46px",
      "w": "46px",
      "min-w": "0",
      "m": "0",
      "p": "5px",
      "rounded-right": "0",
      "is-disabled": !_vm.hasCheckedMeals
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.decrementDose();
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "minus",
      "size": "16px"
    }
  })], 1), _c('c-input', {
    attrs: {
      "rounded": "0",
      "h": "46px",
      "w": "64px",
      "type": "number",
      "is-disabled": !_vm.hasCheckedMeals
    },
    model: {
      value: _vm.dose,
      callback: function callback($$v) {
        _vm.dose = _vm._n($$v);
      },
      expression: "dose"
    }
  }), _c('c-button', {
    attrs: {
      "flex-shrink": "0",
      "variant": "outline",
      "variant-color": "primary",
      "min-w": "0",
      "h": "46px",
      "w": "46px",
      "m": "0",
      "p": "5px",
      "rounded-left": "0",
      "is-disabled": !_vm.hasCheckedMeals
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.incrementDose();
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "add",
      "size": "16px"
    }
  })], 1)], 1)], 1)], 1), _c('c-button', {
    attrs: {
      "variant": "ghost",
      "h": "24px",
      "w": "24px",
      "min-h": "24px",
      "min-w": "24px",
      "m": "0",
      "p": "0",
      "d": ['auto', 'none'],
      "ml": "auto",
      "_focus": {
        outline: 'none',
        boxShadow: 'none'
      }
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = !_vm.isOpen;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "alt": "icon collapse",
      "h": "24px",
      "w": "24px",
      "transform": _vm.isOpen ? ['rotate(-90deg)'] : ['rotate(90deg)'],
      "animation": "2s transform ease"
    }
  })], 1)], 1), _c('c-collapse', {
    attrs: {
      "is-open": _vm.isOpen,
      "ml": [0, '20px'],
      "py": [0, '3px']
    }
  }, [_c('c-text', {
    attrs: {
      "mt": ['10px', 0],
      "font-size": "12px",
      "color": "#828282"
    }
  }, [_vm._v(" Pilihan golongan makanan ")]), _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '10px'
      },
      expression: "{ gap: '10px' }"
    }],
    attrs: {
      "mt": "10px",
      "mx": "10px",
      "flex-wrap": "wrap",
      "justify-content": "flex-start"
    }
  }, _vm._l(_vm.meals, function (meal, index) {
    return _c('FormCardMakananItem', {
      key: "".concat(_vm.category, "-").concat(meal._id, "-").concat(index),
      ref: "cardMakananItemForm",
      refInFor: true,
      attrs: {
        "value": meal
      },
      on: {
        "input": _vm.onItemInput
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }