var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.disabled': {
          borderColor: '#C4C4C4',
          backgroundColor: 'superLightGray.900',
          color: 'black.900',
          cursor: 'not-allowed',
          pointerEvents: 'none',
          button: {
            borderColor: '#C4C4C4'
          }
        }
      },
      expression: "{\n      '.disabled': {\n        borderColor: '#C4C4C4',\n        backgroundColor: 'superLightGray.900',\n        color: 'black.900',\n        cursor: 'not-allowed',\n        pointerEvents: 'none',\n        button: {\n          borderColor: '#C4C4C4',\n        },\n      },\n    }"
    }],
    attrs: {
      "flex-wrap": "wrap",
      "margin-top": "30px",
      "mx": "-10px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px",
      "w": "50%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jam Mulai Makan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "id": "time1",
      "type": "time",
      "height": "62px",
      "placeholder": "Masukkan Jam Mulai Makan",
      "required": ""
    },
    model: {
      value: _vm.timeStart,
      callback: function callback($$v) {
        _vm.timeStart = $$v;
      },
      expression: "timeStart"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-jam-grey.svg'),
      "alt": "image"
    }
  })], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px",
      "w": "50%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jam Akhir Makan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "id": "time1",
      "type": "time",
      "height": "62px",
      "placeholder": "Masukkan Jam Berhenti Makan",
      "required": ""
    },
    model: {
      value: _vm.timeEnd,
      callback: function callback($$v) {
        _vm.timeEnd = $$v;
      },
      expression: "timeEnd"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-jam-grey.svg'),
      "alt": "image"
    }
  })], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Jumlah Kalori ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Pilih Kebutuhan Jumlah Kalori",
      "height": "62px"
    },
    model: {
      value: _vm.caloriesNeed,
      callback: function callback($$v) {
        _vm.caloriesNeed = $$v;
      },
      expression: "caloriesNeed"
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10% ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15% ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20% ")]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v(" 25% ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30% ")]), _c('option', {
    attrs: {
      "value": "35"
    }
  }, [_vm._v(" 35% ")]), _c('option', {
    attrs: {
      "value": "40"
    }
  }, [_vm._v(" 40% ")]), _c('option', {
    attrs: {
      "value": "45"
    }
  }, [_vm._v(" 45% ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50% ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px",
      "w": "100%",
      "px": "10px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kkal - Jumlah Kalori ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "id": "kKal",
      "type": "text",
      "error-border-color": "red.300",
      "value": _vm.jumlahKaloriPercent,
      "height": "62px",
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "p": "8px",
      "mt": "5px",
      "mb": "20px",
      "background": "#F2F9F9",
      "border-radius": "8px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-info-circle-grey.svg'),
      "alt": "icon info",
      "h": "18px",
      "w": "18px"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "8px",
      "font-size": "12px",
      "color": "#555555"
    }
  }, [_vm._v(" Daftar golongan makanan di bawah ini opsional. ")])], 1), _vm._l(_vm.meals_, function (item) {
    return _c('FormCardMakanan', {
      key: "".concat(_vm.category, "-").concat(item._id, "-").concat(item.name),
      ref: "cardMakananForm",
      refInFor: true,
      attrs: {
        "category": _vm.category,
        "title": item.name,
        "portion": _vm.getPortionFor(item.name),
        "value": item.items
      },
      on: {
        "portion-changed": function portionChanged($event) {
          return _vm.onPortionChangedFor(item.name, $event);
        }
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }