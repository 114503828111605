var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "m": "0",
      "p": "0",
      "w": "72px",
      "h": "auto",
      "flex-dir": "column",
      "align-items": "center",
      "font-weight": "initial"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "w": "auto",
      "h": "auto",
      "min-w": "0",
      "pos": "relative",
      "is-invalid": _vm.$v.$invalid,
      "border-width": _vm.$v.$invalid ? '2px' : undefined,
      "border-color": _vm.$v.$invalid ? '#e66673' : undefined
    },
    on: {
      "click": function click($event) {
        _vm.checked = !_vm.checked;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.photoURL,
      "alt": _vm.value.foodIngredient,
      "h": "72px",
      "w": "72px",
      "object-fit": "cover",
      "box-shadow": "0px 0px 8px 5px rgba(0, 0, 0, 0.12)",
      "border-radius": "8px",
      "opacity": _vm.checked ? 1 : 0.7
    }
  }), _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "align-items": "center",
      "justify-content": "center",
      "h": "24px",
      "w": "24px",
      "top": "5px",
      "right": "5px",
      "bg": _vm.checked ? 'primary.400' : 'blackAlpha.400',
      "p": "5px",
      "border-radius": "8px"
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "check",
      "size": "14px",
      "color": "white"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "m": "0",
      "mt": "5px",
      "font-size": "12px",
      "text-align": "center",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.foodIngredient) + " ")]), _c('c-flex', [_c('c-input', {
    attrs: {
      "type": "number",
      "name": "dose",
      "p": "0",
      "font-size": "12px",
      "text-align": "center",
      "h": "25px",
      "is-disabled": !_vm.checked,
      "is-invalid": _vm.$v.dose.$invalid
    },
    model: {
      value: _vm.dose,
      callback: function callback($$v) {
        _vm.dose = _vm._n($$v);
      },
      expression: "dose"
    }
  }), _c('c-input', {
    attrs: {
      "type": "text",
      "name": "unit",
      "p": "0",
      "font-size": "12px",
      "text-align": "center",
      "h": "25px",
      "is-disabled": !_vm.checked,
      "is-invalid": _vm.$v.doseUnit.$invalid
    },
    model: {
      value: _vm.doseUnit,
      callback: function callback($$v) {
        _vm.doseUnit = $$v;
      },
      expression: "doseUnit"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }