var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "overflow": "auto",
      "pb": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "px": "40px",
      "py": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "space-between"
    }
  }, [_c('c-heading', {
    attrs: {
      "color": "black.900",
      "font-size": "24px",
      "font-weight": "700",
      "margin-bottom": "40px"
    }
  }, [_vm._v(" Rencana makan ")]), _c('c-button', {
    attrs: {
      "background-color": "primary.400",
      "font-size": "16px",
      "font-weight": "700",
      "font-family": "Roboto",
      "px": "20px",
      "border-radius": "100px",
      "color": "white",
      "is-disabled": _vm.isInvalid,
      "disabled": _vm.isInvalid
    },
    on: {
      "click": function click($event) {
        return _vm.trySubmit();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/save-icon.svg'),
      "alt": "image",
      "margin-right": "8px"
    }
  }), _vm._v(" Simpan ")], 1)], 1), _c('c-tabs', {
    attrs: {
      "margin-top": "30px",
      "variant": "soft-rounded",
      "variant-color": "green"
    }
  }, [_c('c-tab-list', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        button: {
          color: 'lightGray.900',
          fontWeight: '700',
          mb: '10px',
          align: 'center',
          pos: 'relative',
          h: '8px',
          w: '100%',
          maxW: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          py: '0',
          px: '0',
          marginRight: '10px',
          fontSize: '16px',
          lineHeight: '24px'
        },
        'button[aria-selected=true]': {
          backgroundColor: 'primary.400',
          color: 'primary.400'
        }
      },
      expression: "{\n          button: {\n            color: 'lightGray.900',\n            fontWeight: '700',\n            mb: '10px',\n            align: 'center',\n            pos: 'relative',\n            h: '8px',\n            w: '100%',\n            maxW: '156px',\n            borderRadius: '20px',\n            backgroundColor: 'superLightGray.900',\n            py: '0',\n            px: '0',\n            marginRight: '10px',\n            fontSize: '16px',\n            lineHeight: '24px',\n          },\n          'button[aria-selected=true]': {\n            backgroundColor: 'primary.400',\n            color: 'primary.400',\n          },\n        }"
    }]
  }, [_c('c-tab', {
    attrs: {
      "bg": "blue"
    }
  }, [_c('c-text', {
    attrs: {
      "margin-top": "-42px"
    }
  }, [_vm._v(" Sarapan ")])], 1), _c('c-tab', [_c('c-text', {
    attrs: {
      "margin-top": "-42px"
    }
  }, [_vm._v(" Snack Pagi ")])], 1), _c('c-tab', [_c('c-text', {
    attrs: {
      "margin-top": "-42px"
    }
  }, [_vm._v(" Makan Siang ")])], 1), _c('c-tab', [_c('c-text', {
    attrs: {
      "margin-top": "-42px"
    }
  }, [_vm._v(" Snack Siang ")])], 1), _c('c-tab', [_c('c-text', {
    attrs: {
      "margin-top": "-42px"
    }
  }, [_vm._v(" Makan Malam ")])], 1), _c('c-tab', [_c('c-text', {
    attrs: {
      "mt": "-42px"
    }
  }, [_vm._v(" Snack Malam ")])], 1)], 1), _c('c-tab-panels', [_c('c-tab-panel', {
    attrs: {
      "data-id": "breakfast"
    }
  }, [_c('EditMealPlanForm', {
    key: "breakfast",
    ref: "breakfastForm",
    attrs: {
      "category": "breakfast",
      "value": _vm.rencanaMakan_.breakfast
    },
    on: {
      "submit": function submit($event) {
        return _vm.trySubmit();
      }
    }
  })], 1), _c('c-tab-panel', {
    attrs: {
      "data-id": "morning_snack"
    }
  }, [_c('EditMealPlanForm', {
    key: "morning_snack",
    ref: "morningSnackForm",
    attrs: {
      "category": "morning_snack",
      "value": _vm.rencanaMakan_.morning_snack
    },
    on: {
      "submit": function submit($event) {
        return _vm.trySubmit();
      }
    }
  })], 1), _c('c-tab-panel', {
    attrs: {
      "data-id": "lunch"
    }
  }, [_c('EditMealPlanForm', {
    key: "lunch",
    ref: "lunchForm",
    attrs: {
      "category": "lunch",
      "value": _vm.rencanaMakan_.lunch
    },
    on: {
      "submit": function submit($event) {
        return _vm.trySubmit();
      }
    }
  })], 1), _c('c-tab-panel', {
    attrs: {
      "data-id": "afternoon_snack"
    }
  }, [_c('EditMealPlanForm', {
    key: "afternoon_snack",
    ref: "afternoonSnackForm",
    attrs: {
      "category": "afternoon_snack",
      "value": _vm.rencanaMakan_.afternoon_snack
    },
    on: {
      "submit": function submit($event) {
        return _vm.trySubmit();
      }
    }
  })], 1), _c('c-tab-panel', {
    attrs: {
      "data-id": "dinner"
    }
  }, [_c('EditMealPlanForm', {
    key: "dinner",
    ref: "dinnerForm",
    attrs: {
      "category": "dinner",
      "value": _vm.rencanaMakan_.dinner
    },
    on: {
      "submit": function submit($event) {
        return _vm.trySubmit();
      }
    }
  })], 1), _c('c-tab-panel', {
    attrs: {
      "data-id": "night_snack"
    }
  }, [_c('EditMealPlanForm', {
    key: "night_snack",
    ref: "nightSnackForm",
    attrs: {
      "category": "night_snack",
      "value": _vm.rencanaMakan_.night_snack
    },
    on: {
      "submit": function submit($event) {
        return _vm.trySubmit();
      }
    }
  })], 1)], 1)], 1)], 1), _vm.showError ? _c('Portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('c-alert-dialog', {
    attrs: {
      "is-open": ""
    }
  }, [_c('c-alert-dialog-overlay'), _c('c-alert-dialog-content', {
    attrs: {
      "pos": "fixed",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('c-alert-dialog-body', {
    attrs: {
      "py": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]), _c('c-alert-dialog-footer', [_c('c-button', {
    attrs: {
      "mx": "5px",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.showError = false;
      }
    }
  }, [_vm._v(" Tidak ")]), _c('c-button', {
    attrs: {
      "ml": "5px",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" Yakin ")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }