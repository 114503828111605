<template>
  <c-flex
    :flex-dir="['column', 'row']"
    mx="15px"
  >
    <c-flex
      :align-items="['center', 'flex-start']"
      :min-w="['250px']"
    >
      <c-flex flex-dir="column">
        <c-text
          font-weight="500"
          font-size="18px"
          color="primary.400"
          text-transform="capitalize"
        >
          {{ title }}
        </c-text>
        <c-box mt="10px">
          <c-text
            font-weight="500"
            font-size="16px"
            line-height="150%"
            color="#333333"
          >
            Jumlah porsi
          </c-text>
          <c-box
            d="flex"
            align-items="center"
            h="46px"
            mt="5px"
          >
            <c-button
              flex-shrink="0"
              variant="outline"
              variant-color="primary"
              h="46px"
              w="46px"
              min-w="0"
              m="0"
              p="5px"
              rounded-right="0"
              :is-disabled="!hasCheckedMeals"
              @click.prevent="decrementDose()"
            >
              <c-icon
                name="minus"
                size="16px"
              />
            </c-button>
            <c-input
              v-model.number="dose"
              rounded="0"
              h="46px"
              w="64px"
              type="number"
              :is-disabled="!hasCheckedMeals"
            />
            <c-button
              flex-shrink="0"
              variant="outline"
              variant-color="primary"
              min-w="0"
              h="46px"
              w="46px"
              m="0"
              p="5px"
              rounded-left="0"
              :is-disabled="!hasCheckedMeals"
              @click.prevent="incrementDose()"
            >
              <c-icon
                name="add"
                size="16px"
              />
            </c-button>
          </c-box>
        </c-box>
      </c-flex>
      <c-button
        variant="ghost"
        h="24px"
        w="24px"
        min-h="24px"
        min-w="24px"
        m="0"
        p="0"
        :d="['auto', 'none']"
        ml="auto"
        :_focus="{
          outline: 'none',
          boxShadow: 'none',
        }"
        @click="isOpen = !isOpen"
      >
        <c-image
          :src="require('@/assets/icon-chevron-right.svg')"
          alt="icon collapse"
          h="24px"
          w="24px"
          :transform="isOpen ? ['rotate(-90deg)'] : ['rotate(90deg)']"
          animation="2s transform ease"
        />
      </c-button>
    </c-flex>
    <c-collapse
      :is-open="isOpen"
      :ml="[0, '20px']"
      :py="[0, '3px']"
    >
      <c-text
        :mt="['10px', 0]"
        font-size="12px"
        color="#828282"
      >
        Pilihan golongan makanan
      </c-text>
      <c-flex
        v-chakra="{ gap: '10px' }"
        mt="10px"
        mx="10px"
        flex-wrap="wrap"
        justify-content="flex-start"
      >
        <FormCardMakananItem
          v-for="(meal, index) in meals"
          ref="cardMakananItemForm"
          :key="`${category}-${meal._id}-${index}`"
          :value="meal"
          @input="onItemInput"
        />
      </c-flex>
    </c-collapse>
  </c-flex>
</template>

<script>
import FormCardMakananItem from './form-card-makanan-item.vue'
import equal from 'fast-deep-equal'
import _ from 'lodash'

export default {
  name: 'FormCardMakanan',
  components: { FormCardMakananItem },
  props: {
    category: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    portion: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: true,
      isPopupOpen: false,
      meals: this.value ?? [],
      dose: this.portion?.dose ?? 1.0,
      doseUnit: this.portion?.doseUnit ?? 'porsi',
    }
  },
  computed: {
    hasCheckedMeals() {
      let checkeds = this.meals.map((v) => v.checked)
      return checkeds.some(it => it === true)
    },
    _portion() {
      return {
        dose: this.dose,
        doseUnit: this.doseUnit,
      }
    },
    portionData() {
      return { portion: this._portion, meals: this.meals }
    },
  },
  watch: {
    portionData: {
      immediate: true,
      handler(data, old) {
        if (equal(data, old)) return

        let { portion, meals } = data
        if (portion == null || meals == null) return

        let checked = meals.some((it) => it.checked === true)
        if (checked === true) {
          this.$emit('portion-changed', portion)
        }
      },
    },
    value: {
      // immediate: true,
      deep: true,
      handler(value, old) {
        if (equal(value, old)) return

        this.meals = value ?? []
        // for (let i = 0; i < this.meals.length; i++) {
        //   let item = this.meals[i];
        //
        //   if (item._id == null) {
        //     item._id = crypto.randomUUID()
        //     this.$set(this.meals, i, item)
        //   }
        // }
      },
    },
    meals: {
      deep: true,
      handler: _.debounce(function(value, old) {
        if (equal(value, old)) return

        // this.$emit("input", value);
      }, 500),
    },
  },
  methods: {
    onItemInput: _.throttle(function(meal) {
      if (meal._id != null) {
        let index = this.meals.findIndex((item) => item._id === meal._id || item.foodIngredient === meal.foodIngredient)

        if (index !== -1) {
          this.$set(this.meals, index, {
            ...this.meals[index],
            ...meal,
          })
        }
      }
    }, 300),
    incrementDose() {
      this.dose = parseFloat((this.dose + 0.1).toFixed(1))
    },
    decrementDose() {
      if (this.dose <= 0) return
      this.dose = parseFloat((this.dose - 0.1).toFixed(1))
    },
  },
}
</script>
